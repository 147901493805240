import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import Hero from '../components/hero'

// Load Page Contents
import contentEn from '../../content/pages/contact-us.en.yaml'
import contentId from '../../content/pages/contact-us.id.yaml'
import { useTranslation } from 'react-i18next'
import SEO from '../components/seo'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default function ContactPage() {
  const { i18n } = useTranslation()
  const [content, setContent] = React.useState(contentEn)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      <Hero>
        <h1 className="uk-heading-small">{content.title}</h1>
        <p className="uk-width-xlarge">{content.description}</p>
      </Hero>

      <section
        className="uk-margin-large"
        // style={{ marginTop: -100 }}
      >
        <div className="uk-container">
          <div className="">
            <div className="">
              <div className="uk-grid-collapse" uk-grid="">
                <div className="uk-width-expand">
                  <div className="uk-tile uk-tile-default uk-box-shadow-large">
                    <h3>{content.send_us_a_message}</h3>
                    {/* <div className="uk-margin">
                  <input className="uk-input" name="name" type="text" placeholder="Your Name" />
                </div>

                <div className="uk-margin">
                  <input className="uk-input" name="email" type="text" placeholder="Your Email" />
                </div>

                <div className="uk-margin">
                  <textarea className="uk-textarea" rows={5} placeholder="Your Message"></textarea>
                </div>

                <div className="uk-margin uk-text-right">
                  <button className="uk-button uk-button-primary">Submit</button>
                </div> */}
                    <iframe
                      width="100%"
                      height="550"
                      src="https://crm.jasnita.co.id/forms/wtl/44b82fd4002a7437ad18e7453440e95f"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
                <div className="uk-width-1-3@m uk-grid-item-match">
                  <div className="uk-tile">
                    <h3>{content.contact_information}</h3>
                    <ul className="uk-list">
                      <li className="uk-flex">
                        <span
                          className="uk-margin-right uk-flex-none"
                          style={{ paddingTop: 5 }}
                          uk-icon="location"
                        ></span>
                        <a href="https://g.page/jasnitatel?share" target="_blank" rel="noreferrer noopener">
                          E-Trade Building lt. 5 Jl. K.H. Wahid Hasyim no. 55, Kebon Sirih, Jakarta Pusat 10350,
                          Indonesia
                        </a>
                      </li>
                    </ul>
                    <h5>{content.sales}:</h5>
                    <ul className="uk-list">
                      <li>
                        <span className="uk-margin-right" uk-icon="receiver"></span>
                        <a href="tel:+622128565288">(021)-2856-5288</a>
                      </li>
                      <li>
                        <span className="uk-margin-right" uk-icon="whatsapp"></span>
                        <a href="https://wa.me/6281119015555">(0811)-1901-5555</a>
                      </li>
                      <li>
                        <span className="uk-margin-right" uk-icon="mail"></span>
                        <a href="mailto:sales@jasnita.co.id">sales@jasnita.co.id</a>
                      </li>
                    </ul>
                    <h5>{content.call_center_and_technical_support}:</h5>
                    <ul className="uk-list">
                      <li>
                        <span className="uk-margin-right" uk-icon="receiver"></span>
                        <a href="tel:+622128565288">(0811)-343-7859</a>
                      </li>
                      <li>
                        <span className="uk-margin-right" uk-icon="whatsapp"></span>
                        <a href="https://wa.me/628113437859">(0811)-343-7859</a>
                      </li>
                      <li>
                        <span className="uk-margin-right" uk-icon="mail"></span>
                        <a href="mailto:noc@jasnita.co.id">noc@jasnita.co.id</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
